[tooltip] {
    position: relative;
    z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[tooltip]:before,
[tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + .3125rem);
    /* size of the arrow */
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
}

/* The actual tooltip with a dynamic width */
[tooltip]:before {
    content: attr(tooltip);
    padding: .625rem 1.125rem;
    min-width: 3.125rem;
    max-width: 18.75rem;
    width: max-content;
    width: -moz-max-content;
    border-radius: .375rem;
    font-size: .875rem;
    background-color: #3b4850e6;
    background-image: linear-gradient(30deg,
            #3b485070,
            #3b444b70,
            #3c525870);
    box-shadow: 0 0 1.5rem #0003;
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, -.3125rem) scale(0.5);
}

/* Tooltip arrow */
[tooltip]:after {
    content: '';
    border-style: solid;
    border-width: .3125rem .3125rem 0 .3125rem;
    /* CSS triangle */
    border-color: #374046e6 transparent transparent transparent;
    transition-duration: 0s;
    transform-origin: top;
    /* Orientation setting for the slide-down effect */
    transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[tooltip]:hover:before,
[tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -.3125rem) scale(1);
}

/*
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[tooltip]:hover:after {
    transition-delay: 0.5s;
    /* Starting after the grow effect */
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
}


/* LEFT */
/* Tooltip + arrow */
[tooltip-location="left"]:before,
[tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + .3125rem);
    bottom: 50%;
}

/* Tooltip */
[tooltip-location="left"]:before {
    transform: translate(-.3125rem, 50%) scale(0.5);
}

[tooltip-location="left"]:hover:before {
    transform: translate(-.3125rem, 50%) scale(1);
}

/* Arrow */
[tooltip-location="left"]:after {
    border-width: .3125rem 0 .3125rem .3125rem;
    border-color: transparent transparent transparent #374046e6;
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
}

[tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1);
}



/* RIGHT */
[tooltip-location="right"]:before,
[tooltip-location="right"]:after {
    left: calc(100% + .3125rem);
    bottom: 50%;
}

[tooltip-location="right"]:before {
    transform: translate(.3125rem, 50%) scale(0.5);
}

[tooltip-location="right"]:hover:before {
    transform: translate(.3125rem, 50%) scale(1);
}

[tooltip-location="right"]:after {
    border-width: .3125rem .3125rem .3125rem 0;
    border-color: transparent #374046e6 transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
}

[tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1);
}



/* BOTTOM */
[tooltip-location="bottom"]:before,
[tooltip-location="bottom"]:after {
    top: calc(100% + .3125rem);
    bottom: auto;
}

[tooltip-location="bottom"]:before {
    transform: translate(-50%, .3125rem) scale(0.5);
}

[tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, .3125rem) scale(1);
}

[tooltip-location="bottom"]:after {
    border-width: 0 .3125rem .3125rem .3125rem;
    border-color: transparent transparent #374046e6 transparent;
    transform-origin: bottom;
}
